.DateSelector__component {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}

.DateSelector__arrow {
  margin: 18px;
  color: #3f83f8;
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .DateSelector__component {
    margin-top: 24px;
  }
}
