.Card__component {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px lightgray;
  margin: 0 16px 48px 16px;
  border-radius: 8px;
  max-width: 480px;
  overflow: hidden;
  color: #1a202e;
  font-size: 14px;
}

.Card__label {
  padding: 16px 16px 4px 16px;
}

.Card__label__section {
  margin: 12px 0;
}

.Card__label__author__section {
  margin: 2px 0 12px;
}

.Card__text__header {
  font-size: 18px;
  font-weight: 600;
}

.Card__text__theBibleProject {
  font-size: 12px;
  font-weight: normal;
  color: #97a6ba;
  margin: 4px 0 12px;
}

.Card__text__emphasize {
  font-weight: bold;
}

iframe {
  border: 0;
}

@media screen and (max-width: 550px) {
  .Card__label {
    padding: 12px 16px 4px 16px;
  }
}
