.version {
  text-align: center;
  color: #ccc;
  font-size: 80%;
}

html {
  padding: env(safe-area-inset);
}

.disclaimer {
  text-align: center;
  color: #555;
  font-size: 80%;
}
