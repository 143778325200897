.Navigation__component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fff;
  height: 56px;
  box-shadow: 0px 2px 5px lightgray;
}

.Navigation__logo {
  display: flex;
  align-items: center;
}

.Navigation__title {
  margin-left: 10px;
  color: #565656;
  font-size: 18px;
  font-weight: 500;
}

.Navigation__items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 760px;
  margin: 0 16px;
}

@media screen and (max-width: 550px) {
  .Navigation__component {
    box-shadow: 0px 2px 3px #dbdbdb;
  }
  .Navigation__title {
    margin-left: 4px;
    font-size: 16px;
  }
}
