.Button__component {
    margin-left: 4px;
    color: #565656;
    border: 1px solid white;
    padding: 4px 16px;
    border-radius: 4px;
    background: white;
    outline: none;
    font-size: 16px;
}

.selected {
    color: #F7FAFC;
    background: #3F83F8;
}

.Button__component:active {
    background: #afe2ff;
}

.Button__component:hover {
    cursor: pointer;
}

.Button__text {
    padding-top: 1px;
}

@media screen and (max-width: 550px) {
    .Button__component {
        font-size: 14px;
        margin-left: 2px;
        padding: 4px 12px;
    }
}
