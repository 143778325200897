.Date__component {
  display: flex;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  border: 2px solid #3f83f8;
  justify-content: center;
  align-items: center;
  margin: 12px;
  flex-direction: column;
  cursor: pointer;
}

.Date__isCenterDate {
  height: 64px;
  width: 64px;
  background: #3f83f8;
}

.Date__dayOfWeek {
  font-size: 14px;
  color: #475569;
}

.Date__dayOfWeek__isCenterDate {
  color: #fbfdfe;
}

.Date__calendarDate {
  font-size: 12px;
  color: #97a6ba;
}

.Date__calendarDate__isCenterDate {
  color: #c3ddfd;
}

@media screen and (max-width: 550px) {
  .Date__component {
    height: 48px;
    width: 48px;
    margin: 6px;
  }

  .Date__dayOfWeek {
    font-size: 12px;
  }

  .Date__calendarDate {
    font-size: 8px;
  }
}
