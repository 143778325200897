.DownloadReadingPlan__component {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 24px 0px;
  background: #ccc;
  padding: 0px 16px;
}

.DownloadReadingPlan__component a {
  font-size: 18px;
  color: #475569;
  text-decoration: underline;
  font-weight: 600;
}

@media screen and (max-width: 550px) {
    .DownloadReadingPlan__component  a {
        font-size: 18px;
    }
}
