.Text__component {
    font-size: 14px;
}

.header {
    font-size: 18px;
    font-weight: 600;
}

.author {
    font-size: 12px;
    font-weight: normal;
    color: #97A6BA;
    margin: 4px 0 12px;
}

.emphasize {
    font-weight: bold;
}

@media screen and (max-width: 550px) {
    .Text__component {
        font-size: 12px;
    }
    .header {
        font-size: 16px;
    }
}
