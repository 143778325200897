body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

.App__component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 0 100px;
  padding: 0px;
}

.SpeechBubbleText {
  color: #EBF5FF;
  font-size: 16px;
  font-weight: 400;
}
