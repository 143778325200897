.BiblePassages__component {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.BiblePassages__text {
  margin-top: 4px;
}

.BiblePassages__text a {
  color: #3f83f8;
  text-decoration: none;
  font-weight: bold;
}

.BiblePassages__desc {
  padding: 0 24px;
  max-width: 40em;
  text-align: left;
  margin: 0 auto;
}

.BiblePassages__footnote {
  padding-top: 48px;
  font-size: 60%;
  margin: 0 auto;
}

@media screen and (max-width: 550px) {
  .BiblePassages__component {
    font-size: 14px;
  }
  .BiblePassages__text {
    margin-top: 0px;
  }
}
